import { Col, Row } from "react-styled-flexboxgrid";

import BlogRoll from "../components/sections/BlogRoll/BlogRoll.js";
import BlogTags from "../components/sections/BlogTags/BlogTags.js";
import { Helmet } from "react-helmet";
import Layout from "../components/layout/Layout";
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import SectionTitle from "../components/partials/SectionTitle/SectionTitle.js";
import { StyledGrid } from "../helpers/helpers.js";
import { graphql } from "gatsby";

const TagRoute = ({ data, pageContext }) => {
  const posts = data.allMarkdownRemark.edges;
  const tag = pageContext.tag;
  const title = data.site.siteMetadata.title;
  return (
    <Layout>
      <StyledGrid className="BlogPage">
        <Row>
          <SectionTitle
            text={data.blogContent.frontmatter.title}
            highlight={data.blogContent.frontmatter.highlighted}
          />
          <Col xs={12} md={7} mdOffset={2}>
            <p style={{ marginBottom: "32px" }}>
              {data.blogContent.frontmatter.description}
            </p>
            <BlogTags />
          </Col>
        </Row>
        <ScrollAnimation animateIn="fadeInBottom" duration={1} offset={0}>
          <BlogRoll filteredPosts={posts} />
        </ScrollAnimation>
      </StyledGrid>
      <Helmet title={`${tag} | ${title}`} />
    </Layout>
  );
};

export default TagRoute;

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    blogContent: markdownRemark(frontmatter: { templateKey: { eq: "blog-content" } }) {
      frontmatter {
        description
        title
        highlighted
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          excerpt(pruneLength: 144)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            tags
            date(formatString: "MMMM DD, YYYY")
            featuredPost
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 512, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

import "./ArticleCard.scss";

import GroupButton from "../GroupButton/GroupButton.js";
import { Link } from "gatsby";
import PreviewCompatibleImage from "../../../components/PreviewCompatibleImage";
import React from "react";

const ArticleCard = ({
  title,
  tags,
  excerpt,
  slug,
  image,
  isHorizontal,
  withFrame,
}) => {
  return (
    <div className={`ArticleCard ${isHorizontal ? "horizontal" : ""}`}>
      <Link to={slug} title={"Consulter l'article"}>
        <PreviewCompatibleImage imageInfo={image} className="image" />
        <div className={`content ${withFrame ? "with-frame" : ""}`}>
          {tags?.[0] && <span>{tags?.[0]}</span>}
          <h3>{title}</h3>
          <p>{excerpt}</p>
        </div>
      </Link>
      {!!isHorizontal && <GroupButton text="Découvrir" to={slug} />}
    </div>
  );
};

export default ArticleCard;

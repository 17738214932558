import "./SectionTitle.scss";

import { Col } from "react-styled-flexboxgrid";
import React from "react";

const SectionTitle = ({ text, highlight, isRight, isH1 }) => {
  return (
    <Col
      xs={12}
      xsOffset={0}
      md={11}
      mdOffset={1}
      className={`SectionTitle ${isRight ? "right" : ""}`}
    >
      {isH1 ? (
        <h1>
          {text}
          <span className="highlight">{highlight}</span>
        </h1>
      ) : (
        <h2>
          {text}
          <span className="highlight">{highlight}</span>
        </h2>
      )}
    </Col>
  );
};

export default SectionTitle;

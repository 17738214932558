import "./BlogRoll.scss";

import { Col, Row } from "react-styled-flexboxgrid";
import { graphql, useStaticQuery } from "gatsby";

import ArticleCard from "../../partials/ArticleCard/ArticleCard.js";
import React from "react";

export default ({filteredPosts }) => {
  const data = useStaticQuery(
    graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 144)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                tags
                date(formatString: "MMMM DD, YYYY")
                featuredPost
                featuredImage {
                  childImageSharp {
                    fluid(maxWidth: 512, quality: 90) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  const posts = filteredPosts ? filteredPosts : data.allMarkdownRemark.edges;

  return (
    <Row className="BlogRoll">
      {posts &&
        posts.map(({ node: post }, i) => (
          <Col
            xs={12}
            md={5}
            mdOffset={i % 2 === 0 ? 1 : 0}
            key={i}
            className="card-container"
          >
            <ArticleCard
              slug={post.fields.slug}
              tags={post.frontmatter.tags}
              title={post.frontmatter.title}
              excerpt={post.excerpt}
              image={post.frontmatter.featuredImage}
            />
          </Col>
        ))}
    </Row>
  );
};

import { Link, graphql, useStaticQuery } from "gatsby";
import React, { useEffect, useState } from "react";

import { kebabCase } from "lodash";

const BlogTags = () => {
  const data = useStaticQuery(graphql`
    query TagsQuery {
      allMarkdownRemark(limit: 1000) {
        group(field: frontmatter___tags) {
          fieldValue
          totalCount
        }
      }
    }
  `);
  const [location, setLocation] = useState("/");
  useEffect(() => {
    setLocation(window.location.pathname);
  }, []);
  const group = data.allMarkdownRemark.group;
  return (
    <ul className="BlogTags">
      <li>
        <Link
          to={`/blog/`}
          className={
            location === "/blog" || location === "/blog/" ? "active" : ""
          }
        >
          Tous
        </Link>
      </li>
      {group.map((tag) => (
        <li key={tag.fieldValue}>
          <Link
            to={`/blog/${kebabCase(tag.fieldValue)}/`}
            className={location === `/blog/${kebabCase(tag.fieldValue)}/` ? "active" : ""}
          >
            {tag.fieldValue}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default BlogTags;
